import { lazyLoadAnalytics } from '../../../../Common/Resources/src_front/js/components/lazyLoadAnalytics';
import { gtm } from './components/analytics/gtm';
import { pushengage } from './components/analytics/pushengage';
import { initMixpannel } from '../../../../Common/Resources/src_front/js/mixpannel/initMixpannel';

document.addEventListener('DOMContentLoaded', function () {
    lazyLoadAnalytics(gtm);
    lazyLoadAnalytics(pushengage);
    initMixpannel();
});
